/* Chatbot Container */
.chat-container {
  width: 620px !important;
  /* Increased width for better spacing */
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.b-d {
  border: 1px solid;
}

.close-button {
  font-size: 32px;
}

/* .chat-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); 
  z-index: 998;
  backdrop-filter: blur(5px); 
}

.chat-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 999;
} */
/* Chatbox */
.chat-box {
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
  padding: 10px;
  background: #ffffff;
  flex-grow: 1;
}

/* Bot Messages */
.bot-message {
  /*background-color: #e9ecef;*/
  color: #333;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  align-self: flex-start;
  max-width: 70%;
}

/* User Messages */
.user-message {
  background-color: #ffc102;
  color: white;
  padding: 0px 10px;
  border-radius: 0.75rem 0.75rem 0.125rem 0.75rem;
  align-self: flex-end;
  max-width: 30%;
  height: 58px;
}

/* Input Container */
.input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background: #fff;
  position: sticky;
  bottom: 0;
  width: 100%;
}

.input-container input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 40px;
}

.year-input-container {
  width: 100%;
}

.send-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background: #0056b3;
}

/* Options Container */
.options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  justify-content: space-between;
  width: 75%;

}

.choose-text {
  font-size: .75rem !important;
  color: rgb(47, 50, 74);
  ;
}

.options-buttons {
  display: flex;
  flex-direction: row;
  /* Display buttons side by side */
  justify-content: center;
  gap: 10px;
}

.options-wrapper {
  animation: 0.2s ease-in-out 0s 1 normal forwards running message-avatar-left-in;
}

.option-button {
  background: #ffcc00;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s;
  width: auto;
  /* Adjust width based on content */
  min-width: 120px;
  text-align: center;
  display: inline-block;
  /* Ensures they align side by side */
  width: calc(50% - 0.5rem);
}

.option-button:hover {
  background: #e6b800;
}

.message-layout {
  display: flex;
  align-items: flex-start;
  /*margin-bottom: 10px;*/
}

.message-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.message-content {
  /*max-width: 75%;*/
  display: flex;
  flex-direction: column;
}

.message-bubble {
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.message-bubble-bot {
  background: #e1e1e1;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.message-time {
  font-size: 12px;
  color: #777;
  margin-top: 3px;
}

.message-content-in {
  animation: 0.6s ease-in-out 0.1s 1 normal forwards running message-content-in;
}

.article-div {
  display: flex;
  position: relative;
  align-items: flex-end;
  max-width: 100%;
  margin: 10px
}

.msg-grid-left {
  margin-right: 1.75rem;
  text-align: center;
  position: relative;
}

.image {
  height: 40px;
  width: 40px;
}

.msg-grid-center {
  width: 410px;
  position: relative;
}

.MessageBubble {
  display: flex;
  overflow: visible;
  border-radius: 0.75rem 0.75rem 0.75rem 0.125rem;
  padding: 1rem 1.5rem;
  max-width: 100%;
  background-color: #f8f9fa;
  color: rgb(47, 50, 74);
  animation: 0.3s ease-in-out 0s 1 normal none running message-bubbl
}

.MessageBubble__content {
  width: 100%;
}

.MessageBubbleText {
  align-self: center;
  word-break: break-word;
  white-space: pre-wrap;
}

.InputText {
  margin-top: 1.125rem;
}

.has-addons {
  display: flex;
  justify-content: flex-start;
}

.has-icons-right {
  flex-grow: 1;
  flex-shrink: 1;
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: inherit;
}

.input {
  padding-right: 58px;
  border-color: #ffc000;
  font-size: 16px;
  border-width: 2px;
  resize: none;
  height: auto;
  min-height: 58px !important;
  white-space: pre-wrap;
  word-break: break-word;
  /* width: 92%; */
  box-shadow: none;
  max-width: 100%;
  width: 100%;
  -webkit-appearance: none;
  align-items: center;
  /* border: 2px solid rgba(0, 0, 0, 0); */
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25rem;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1rem;
  position: relative;
  vertical-align: top;
  padding-right: 58px;
  padding-left: 1.25rem !important;
  font-size: 16px !important;
}

.input-icon-send-button {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 4 !important;
  right: 0;
  justify-content: center;
  padding-bottom: .375em;
  padding-left: .75em;
  padding-right: .75em;
  padding-top: .375em;
  text-align: center;
  white-space: nowrap;
}

.enterText {
  font-size: .75rem !important;
}

.MessageDate {
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 40px;
  text-align: center;
  line-height: 0.8rem;
  color: rgb(47, 50, 74);
  font-size: .75rem !important;
  left: 0;
}

.MessageDate__text {
  font-weight: normal;
}

.isLeft {
  justify-content: flex-end;
}

.msg-grid-right {
  align-self: center;
}

.userMessagedate {
  display: block;
  position: relative;
  margin-right: -0.5rem;
  font-size: .75rem !important;
}

.user-bubble {
  margin: 10px;
}

/* back button */
.back-grid-row {
  display: inline-flex;
  position: relative;
  align-items: flex-end;
  max-width: 100%;
}

.back-grid-left {
  width: 40px;
  margin-right: 1.75rem;
}

.msg-grid-center {
  position: relative;
  min-width: 0px;
}

.back-button-container {
  opacity: 0;
  transition: opacity 0.25s ease-in-out, padding 0.25s ease-in-out;

}

.BackButton {
  /* height: 30px; */
  width: 86px;
  background-color: transparent;
  border-radius: 1rem;
  border-width: 0px;
  padding-top: 11px;

}
.custom-button{
  width: 90px;
}

.custom-button:hover {
  background: #f8f9fa;
  border-radius: 13px;
 
}
.sc-s{
  background-color: #fff;
  transform: translateY(0);
  box-shadow: 0 0 47px rgba(0, 0, 0, 0.2);
}

/* Show the back button with opacity 1 when hovering over the message bubble container */
.message-wrapper:hover .back-button-container {
  opacity: 1;
  transition: opacity 0.25s ease-in-out, padding 0.25s ease-in-out;
}

.message-wrapper:hover .BackButton__Icon .icon {
  transform: rotate(0deg);
  transition: transform 0.25s;
}

.sr-cl {
  border-top-right-radius: 19px !important;
  border-bottom-right-radius: 19px !important;
}

.BackButton__Icon {
  padding-right: 2px;
  font-size: 0.75rem;
  text-align: center;
  white-space: nowrap;
  -webkit-appearance: none;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25rem;
  justify-content: flex-start;
  /* line-height: 1.5; */
  /* padding-bottom: 1rem; */
  /* padding-left: 1.25rem; */
  /* padding-right: 1.25rem; */
  /* padding-top: 1rem; */
  /* position: relative; */
  vertical-align: top;
}

.BackButton__Icon .icon {
  transform: rotate(180deg);
  transition: transform 0.25s;
  /* text-align: center; */
  /* white-space: nowrap; */
  height: 1.5em;
  width: 1.5em;
  display: block;
}

.BackButton__Label {
  color: black;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.fade-in {
  animation: fade-in .6s
}

.left-in {
  animation: left-in .6s
}

@keyframes fade-in {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes left-in {
  0% {
    transform: translateX(-40px)
  }

  100% {
    transform: translateX(0)
  }
}

@keyframes message-bubbl {
  0% {
    transform: translateX(16px) translateY(20px) scale(0.9) rotate(-4deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0) translateY(0) scale(1) rotate(0);
    opacity: 1;
  }
}

@keyframes message-avatar-left-in {
  0% {
    transform: translate(-32px);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}