/* header.scss */

/* Transparent header when at top */
.top-header {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

/* White header after scrolling */
.scrolled-header {
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional for shadow effect */
  transition: background-color 0.3s ease;
}

/* Update logo visibility */
.top-header .default-logo {
  display: block;
}

.top-header .alt-logo {
  display: none;
}

.scrolled-header .alt-logo {
  display: none;
}

/* transform btn start */
.btn-review-now {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 32px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: black;
  color: white;
  border: none;
  z-index: 1;
  transition: color 0.3s ease, background-color 0.3s ease;
  border: 2px solid black;
}

.btn-review-now::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transform: translateX(-100%);
  z-index: -1;
  transition: transform 0.4s ease-out;
}

.btn-review-now:hover::before {
  transform: translateX(0);
}

.btn-review-now:hover {
  color: black;
  background-color: white;
  /* Optional fallback if before fails */
  border: 2px solid black;
}

/* transform btn end */
.yellow-class {
  color: #FFB000 !important;
}

.section-wrappers {
  transition: transform 0.15s linear;
}

.section-wrappers:hover {
  transform: scale(1.05);
}

.black-class {
  color: black !important;
}

/* simple btn  start */
.btn-explore-more {
  display: inline-block;
  padding: 15px 32px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: black;
  color: #fff;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 10px;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-explore-more::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* transform: translateY(100%); */
  transition: transform 0.3s ease;
  z-index: -1;
}


.btn-explore-more:hover {
  background-color: transparent;
  color: black;
  border-color: black;
}

/* simple btn  end*/

/* btn yellow  start*/
.btn-explore-yellow {
  all: unset !important;  /* Resets all Bootstrap button styles */
  display: inline-block !important;
  font-size: 11px !important;
  line-height: normal !important;
  padding: 8px 15px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  background-color: black !important;
  color: #fff !important;
  border: 2px solid black !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  border-radius: 18px !important;
  position: relative !important;
  overflow: hidden !important;
  z-index: 1;
}

.btn-explore-yellow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  transition: transform 0.3s ease !important;
  z-index: -1;
}

.btn-explore-yellow:hover {
  background-color: transparent !important;
  color: black !important;
  border-color: black !important;
}
.btn-explore-yellow:active {
  background-color: transparent !important;
  color: black !important;
  border-color: black !important;
}

.btn-explore-white {
  display: inline-block;
  font-size: 11px;
  line-height: normal;
  padding: 8px 15px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #ffffff00;
  color: black;
  border: 2px solid black;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 18px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.btn-explore-white:active{
  background-color: black !important;
  color: #fff !important;
  border-color: black !important;
}
.bto{
  font-size: 13px !important;
line-height: normal !important;
padding: 11px 23px !important;
border-radius: 20px !important;
}



.btn-explore-white::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: transform 0.3s ease;
  z-index: -1;
}

.btn-explore-white:hover {
  background-color: black;
  color: #fff !important;
  border-color: black;
}

/* btn yellow end*/
.bg-text {
  background: none !important;
}

.hove-cls>div:hover {
  background: linear-gradient(to top right, #FFB000 0%, #FFDF80 100%);
  color: #fff !important;
}

.yellow-i {
  color: #FFB000 !important;
}

.green-i {
  color: #368000 !important;
}

.greenlite-i {
  color: #99ce2d !important;
}

.redlite-i {
  color: #ff6e2c !important;
}

.red-i {
  color: #fe4a29 !important;
}

.yellow-b {
  background: #FFB000 !important;
}

.hove-cls>div {
  transition: all 0.3s ease;
}

.hove-cls>div:hover i {
  color: black !important;
}

.icon-with-text-02.about-us-icon-with-text>div:hover .feature-box-content p,
.icon-with-text-02.about-us-icon-with-text>div:hover .feature-box-content span {
  color: black !important;
}

/* yellow btn start */
.try-now-button {
  /* margin-right: 38px; */
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  background: linear-gradient(to right, #FFBF00, #FFB000);
  color: black;
  font-size: 1.125rem;
  padding: 14px 32px;
  display: inline-block;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  transform: scale(1);
}
.try-now-button:active { 
  background: linear-gradient(to right, #FFBF00, #FFB000) !important;
  color: black !important;
  border: 2px solid black !important;
  transform: scale(0.95); /* Small click effect */
}

.try-now-button:hover {
  background: linear-gradient(to right, #FFB000, #FFBF00);
  color: black;
  border: 2px solid black !important;
}

.list-style-02 li {
  margin-bottom: 10px !important;
}

.border-1 {
  border: 1px solid #e5e5e5
}

.bg-1 {
  background-color: #ffffff66 !important;

}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
  box-shadow: none !important
}

.accordion-item {
  border: none !important;
}

.h-m {
  height: 25px;
  border: 1px solid #dee2e6;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.custm-bg {
  background-color: #f8f9fa !important;
}

.dropdown-toggle:after {
  display: none;
}

.shdo-box {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 15px;
}

.box-total {
  width: fit-content;
  background: #424242;
  border-radius: 21px;
  padding: 7px 15px 0px 16px;
}
.hov-c:hover{
  border: 1px solid #FFB000 !important;
    box-shadow: 0 0 0 .10rem #ffb00066 !important;
}

.modal.fade .modal-dialog {
  min-width: 750px !important;
}

.accordion-style-05 .accordion-body {
  margin-left: 0px !important;
  width: auto !important;
}

.custom-tooltip {
  background-color: #ffffff !important;
  color: black !important;
  font-size: 10px !important;
  text-align: center;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(47, 47, 47, 0.3);
  text-transform: none !important; /* Ensures text is NOT capitalized */
  opacity: 1 !important;
}

.custom-tooltip .tooltip-inner {
  background-color: #ffffff !important;
  color: black !important;
  font-size: 10px !important;
  text-align: center;
  padding: 8px 12px;
  border-radius: 6px;
  text-transform: none !important; /* Prevents uppercase text */
  opacity: 1 !important;
}

.custom-tooltip .tooltip-arrow::before {
  border-top-color: #ffffff !important; /* Makes the arrow white */
  opacity: 1 !important;
}


.custom-tabs .nav-link {
  color: #6b7280;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  padding-bottom: 8px;
}

.custom-tabs .nav-link.active {
  color: #374151 !important;
  border-bottom: 2px solid #374151 !important;
}


.form-control{
  border: 1 px solid !important;
}
.sc-cls:focus{
  border-color:#dee2e6 !important;
  box-shadow: none !important;
}
.sc-cls:hover{
  border-color:#dee2e6 !important;
}
.log-cl {
  color: black !important;
  
  font-weight: bold;
  font-size: 1.1rem; /* Increase font size */
  
}

.log-cl:hover {
  text-decoration: underline; /* Black underline by default */
  text-decoration-color: black !important;
  transition: color 0.3s ease, text-decoration-color 0.3s ease;/* Change underline to yellow on hover */
}
header .navbar-nav > .nav-item.active > .nav-link {
  opacity: 1 !important;
  font-weight: 700;
}
header .navbar-nav > .nav-item > .nav-link:hover{
  text-decoration: underline; /* Black underline by default */
  text-decoration-color: black !important;
  transition: color 0.3s ease, text-decoration-color 0.3s ease;/* Change underline to yellow on hover */
}
.check-cl:hover{
  border-color:black !important;
}
.tab-style-07 .nav-link {
  padding: 18px 40px 18px !important;
}
.h-badge:hover{
  background-color: #f8f9fa !important;
}
.fomc-cls{
  border: 1px solid rgb(0 0 0 / 64%) !important;
}
.text-g{
  color: #606868 !important;
}
.fomc-cls:focus {
  border-color:#FFB000 !important;
  box-shadow: 0 0 0 .10rem #ffb00066 !important;
}
.fomc-cls:hover {
  border-color:black !important;
}
.fomc-cls:hover{
  border-color:black !important;
}

.hrb{
  position: absolute;
  width: 100%;
  left: 2px;

}
.rond-cls{
  height: 26px;
  border-radius: 30px;
  width: 25px;
  line-height: 1.8;
}
.rond-cls:hover{
  
    display: -webkit-inline-box;
    background: #00000012;
    
}
.h-c-r:hover{
  color: blue !important;
}
.m-h{
  height: 702px;
  overflow-y: auto;
}
.animated-box {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background: white;
  text-align: center;
  opacity: 0;
  /* transform: translateY(-20px); */
  animation: fadeInSlideDown 0.6s ease-in-out forwards;
}
@keyframes fadeInSlideDown {
  from {
      opacity: 1;
  }
  to {
      opacity: 1;
  }
}
.react-datepicker-wrapper{
  width: 100% !important;
}
/* Custom Radio Button Styling */
input[type="radio"] {
  appearance: auto !important; /* Ensures default browser style */
  -webkit-appearance: radio !important;
  -moz-appearance: radio !important;
  border: 2px solid #ccc;

  cursor: pointer;
}

/* Change checked color */
input[type="radio"]:checked {
  accent-color: black !important; /* Blue color when selected */
}
/* Custom black checkbox */
input[type="checkbox"] {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 15px;
  height: 15px;
  border: 1px solid black !important; /* Black border */
  background-color: white !important;
  border-radius: 4px; /* Optional: Rounded corners */
  cursor: pointer;
  display: inline-block;
  position: relative;
}

/* Black background when checked */
input[type="checkbox"]:checked {
  background-color: black !important;
  border-color: black !important;
}

/* White checkmark when checked */
input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  top: 1px;
  left: 4px;
  transform: rotate(45deg);
  display: block;
}

.check-l {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 21px !important;
    height: 16px !important;
}
/* White checkmark when checked */
.check-l:checked::after {
  top: 1px !important;
  left: 4px !important;
}
.flr-d {
  display: flex !important;
  justify-content: stretch;
}
.bor-q {
  border-bottom: 2px solid #ddd !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* Only remove the border from the last item */
/* .bor-q:not(:last-of-type) {
  border-bottom: 2px solid #ddd !important;
}
.bor-q:last-of-type {
  border-bottom: none !important;
} */
.accordion-style-05 .accordion-item {
  padding: 20px 20px !important;
}
.bor-q-h {
  border-bottom: 2px solid #ddd !important;
 
}
.j-sa{
  justify-content: space-around !important;
}
.w-j{
  width: 33% !important;
}
.table-b {
  border-collapse: collapse;
  width: 100%;
}
.td-b {
  vertical-align: middle;
  line-height: 1.2;
}
.btn-explore-white:focus{
  border: 2px solid black !important;
  color: black !important;
}
.btn-explore-white:focus:hover {
  color: black !important;
}
footer a:hover {
  color: #FFB000 !important;
}
.downtm:hover{
  color: #FFB000 !important;
}
.uptm:hover{
  color: #FFB000 !important;
}
.fomc-clsr {
  border:1px solid#FFB000 !important;
  box-shadow: 0 0 0 .10rem #ffb00066 !important;
}
.custom-focus-shadow:focus-within {
  border:1px solid#FFB000 !important;
  box-shadow: 0 0 0 .10rem #ffb00066 !important;
}
.max-h-k{
  max-height: 25rem;
}
.m-ml{
  height: 283px;
  overflow: auto;
}
.cl-b{
  border: none !important;
}
.hr-o{
    position: absolute;
    left: -12px; /* Moves the line left to align with the comment avatar */
    width: 2px;
    background-color: #d1d5db; /* LinkedIn's gray */
    height: 100%;
    border: none;
}
.h-m-t {
  border: 1px solid #dee2e6;
  font-weight: 500 !important;
  font-size: 13px !important;
}
.language-suggestions {
  max-height: 250px; /* Adjust height based on item size */
  overflow-y: auto;
  z-index:  1050;
}
.fixed-dropdown {
  position: relative;
  width: 200px; /* Adjust width as needed */
}

.fixed-dropdown option {
  max-height: 200px; /* Set a max height */
  overflow-y: auto; /* Enable scrolling */
}
.cl-plu{
  width: 26px;
  height: 26px;
  border-radius: 13px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.cl-plu:hover{
  background: #e6e7eb;
}
.i-p{
  position: relative;
  bottom: 3px;

}
.modal-header .btn-close :hover{
  border-radius: 50% !;
  background: #e6e7eb !important;
}

@media print {
  .page-break {
      page-break-before: always;
      page-break-after: always;
      break-before: always;
  }
}

@media (max-width: 1024px) {
  .sticky-tabs {
    top: 60px;
  }
}

@media (max-width: 991px) {
  header .navbar-expand-lg .navbar-brand .default-logo {
    opacity: 1 !important;
    visibility: visible !important;
    width: auto !important;
  }
  .m-h{
    height: auto;
    overflow-y: auto;
    overflow-x: hidden !important;
    overflow: hidden !important;
  }
}

@media (max-width: 768px) {
  .sticky-tabs {
    top: 50px;
  }

  .modal.fade .modal-dialog {
    min-width: auto !important;
  }

  .tab-style-07 {
    display: none;
  }
  
}

/* yellow btn end */
/* Change close button color on hover */
.modal-header .btn-close:hover {
  background-color: #e6e7eb; /* Change background */
  border-radius: 50%; /* Optional: Make it circular */

}
.profile_pop_close_btn{
  border-radius: 50%; /* Optional: Make it circular */
  width: 29px;
  height: 29px;

}
.profile_pop_close_btn:hover{
  background-color: #7f7f7f; /* Change background */
  
}
.profile_pic_btn_cls{
  padding: 1rem 2rem;
  padding: 1rem .8rem;
  border-radius: 0.4rem;
}
.profile_pic_btn_cls:hover{
  background-color: #7f7f7f;
  
}